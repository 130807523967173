import React, { useEffect, useState } from "react"
import Loading from "./Loading"
import styles from "./Csv.module.css"

export default function Csv({
  csvUrl,
  columns,
  separator,
  structureFromFile = false,
}) {
  const [state, setState] = useState("idle")
  const [rows, setRows] = useState([])

  useEffect(() => {
    setState("loading")
    window
      .fetch(csvUrl)
      .then(response => response.text())
      .then(text => {
        setRows(text.split("\n").map(l => l.split(separator)))
        setState("idle")
      })
      .catch(err => {
        console.error(err)
        setState("idle")
      })
  }, [])

  return (
    <>
      <p>{state === "loading" ? <Loading text="Hämtar..." /> : null}</p>
      {structureFromFile ? (
        rows
          .reduce(
            (a, cols, i) => {
              const colsWithValues = cols.filter(c => c.trim() !== "")
              if (colsWithValues.length === 1) {
                a.isNewSection = true
                a.sections.push({
                  title: colsWithValues[0],
                  headers: [],
                  rows: [],
                })
              } else if (a.isNewSection) {
                a.isNewSection = false
                a.sections[a.sections.length - 1].headers = cols
              } else {
                a.sections[a.sections.length - 1].rows.push(cols)
              }

              return a
            },
            { isNewSection: true, sections: [] }
          )
          .sections.map((section, i) => (
            <>
              <h2 className="text-lg font-bold mt-12">{section.title}</h2>
              <table className={styles.table}>
                <thead key={i}>
                  <tr>
                    {section.headers.map((h, i) => (
                      <th key={i}>{h}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {section.rows.map((cols, i) => (
                    <tr key={i}>
                      {cols.map((col, i) => (
                        <td key={i}>{col}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ))
      ) : (
        <table className={styles.table}>
          <thead>
            <tr>
              {columns.map(column => (
                <th key={column}>{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((cols, i) => (
              <tr key={i}>
                {columns.map((column, i) => (
                  <td key={i}>{cols[i]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  )
}
