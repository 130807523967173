import React from "react"
import { Link } from "gatsby"

import styles from "./ResultsMenu.module.css"

export default function ResultsMenu({ year }) {
  return (
    <>
      <div className={`text-2xl font-normal text-center max-w-md mx-auto`}>
        {[2021, 2022, 2023, 2024].map(linkYear => (
          <Link
            key={linkYear}
            to={`/resultat/${linkYear}`}
            className={`${styles.link} ${
              year === linkYear ? styles.current : ""
            }`}
          >
            {linkYear}
          </Link>
        ))}
      </div>
      <nav className={styles.menu}>
        {[
          { name: "Totalt", to: `/resultat/${year}` },
          { name: "Spurtpris", to: `/resultat/${year}/spurtpris` },
        ].map(({ name, to }) => (
          <Link
            key={to}
            to={to}
            className={styles.link}
            activeClassName={styles.current}
          >
            {name}
          </Link>
        ))}
      </nav>
    </>
  )
}
